import React from "react";
import './style.css';  // Certifique-se de ter o arquivo de estilo importado
import foto1 from "./foto1.jpg";
import foto2 from "./foto2.jpg";
import foto3 from "./foto3.jpg";

export default function MedioPageIndex({ pharseMedioPagindex, ...props }) {
    return (
        <>
            {DesktopMedio({ pharseMedioPagindex })}
            {MobileNavigation({ pharseMedioPagindex })}
        </>
    );
}

const DesktopMedio = ({ pharseMedioPagindex }) => {
    return (
        <div className="principal-imagens-medio-index-principal-desktop">
            <div className="principal-imagens-medio-index-desktop">
                <img className="foto1-principal-imagens-medio-index-desktop" src={foto1} alt="Foto 1" />
                <img className="foto2-principal-imagens-medio-index-desktop" src={foto2} alt="Foto 2" />
                <img className="foto3-principal-imagens-medio-index-desktop" src={foto3} alt="Foto 3" />
            </div>
            <div className="pharse-principal-imagens-medio-index-desktop">
                <div className="box-pharse-principal-imagens-medio-index-desktop">{pharseMedioPagindex.pharse}</div>
            </div>
        </div>
    )
}

const MobileNavigation = ({ pharseMedioPagindex }) => {
    return (
        <div className="principal-imagens-medio-index-principal-mobile">
            <div className="principal-imagens-medio-index-mobile">
                <img className="foto3-principal-imagens-medio-index-mobile" src={foto3} alt="Foto 3" />
            </div>
            <div className="pharse-principal-imagens-medio-index-mobile">
                <div className="box-pharse-principal-imagens-medio-index-mobile">{pharseMedioPagindex.pharse}</div>
            </div>
        </div>
    );
};