import React from 'react';
import { TopHeadIndex } from './Navegation/index';
import MedioPageIndex from './Medio/index';
import FootComponent from './Foot/index';

export const MainHead = () => {
    var listOptions = { projects: 'Projects', experience: 'Experience', contact: 'Contact', about: 'About' };
    var propitiesOfPage = { logo: "CINDY GALARZA" };
    var pharseMedioPagindex = { pharse: "Aquí se cría arte, aquí no hay límites para la moda." }

    return (
        <>
            <TopHeadIndex listOptions={listOptions} propitiesOfPage={propitiesOfPage} />
            <MedioPageIndex pharseMedioPagindex={pharseMedioPagindex} />
            <FootComponent />
        </>

    );
};