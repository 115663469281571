import React from 'react';
import './style.css';

export default function FootComponent() {
    return (
        <>
            {DesktopMedio()}
            {MobileNavigation()}
        </>
    );
}

const DesktopMedio = () => {
    return (
        <>
            <footer className="desktop-principal-footer">
                <div className="desktop-background-footer">
                    <div className="desktop-group1-foot">
                        <div className='desktop-group1-left'>
                            <div>LIMA - PERU</div>
                            <div><a href="mailto:cindygalarza.h@gmail.com">EMAIL</a></div>
                            <div>ACERCA DE LA MARCA</div>
                        </div>
                        <div className='desktop-group1-rigth'>
                            <div className="desktop-group1-brand-rigth">
                                CINDY GALARZA
                            </div>
                            <div className='desktop-group1-pharse-rigth'>Aquí se cría arte, aquí no hay límites para la moda.</div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
const MobileNavigation = () => {
    return (
        <>
            <footer className="mobile-principal-footer">
                <div className="mobile-background-footer">
                    <div className="mobile-group1-foot">
                        <div className='mobile-group1-left'>
                            <div>LIMA - PERU</div>
                            <div><a href="mailto:cindygalarza.h@gmail.com">EMAIL</a></div>
                            <div>ACERCA DE LA MARCA</div>
                        </div>
                        <div className='mobile-group1-rigth'>
                            <div className="mobile-group1-brand-rigth">
                                CINDY GALARZA
                            </div>
                            <div className='mobile-group1-pharse-rigth'>Aquí se cría arte, aquí no hay límites para la moda.</div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

