import React, { useState } from 'react';
import './index.css';

export const TopHeadIndex = ({ listOptions, propitiesOfPage, ...props }) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <>
      {DesktopHeader({listOptions, propitiesOfPage})}
      {MobileNavigation({ listOptions, propitiesOfPage, toggleMenu,menuOpen})}
    </>
  );
};

const DesktopHeader =({propitiesOfPage,listOptions})=>{
  return(
    <nav id="desktop-nav">
    <div className={`${Object.keys(propitiesOfPage)[0].toLowerCase()}`}>{propitiesOfPage.logo}</div>
    <div>
      <ul className="nav-links">
        {listOptions && Object.keys(listOptions).map((option, index) => (
          <li key={index} className={option.toLowerCase()}>
            <a href={`#${listOptions[option]}`}>{listOptions[option]}</a>
          </li>
        ))}
      </ul>
    </div>
  </nav>
  )
}

const MobileNavigation = ({listOptions, propitiesOfPage, toggleMenu, menuOpen}) => {
  return (
    <nav id="hamburger-nav">
      <div className={`${Object.keys(propitiesOfPage)[0].toLowerCase()}`}>{propitiesOfPage.logo}</div>
      <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`}>
        <div className={`hamburger-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`menu-links ${menuOpen ? 'open' : ''}`}>
          {listOptions && Object.keys(listOptions).map((option, index) => (
            <li key={index + "-hamburger"}><a href={`#${listOptions[option]}`} onClick={toggleMenu}>{listOptions[option]}</a></li>
          ))}
        </div>
      </div>
    </nav>
  );
};