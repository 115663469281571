import "./styles.css";

import { MainHead } from "./components/Index/MainHead.js";

export default function App() {
return (
    <div>
    <MainHead />
    </div>
);
}
